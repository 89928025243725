import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { Container, Row, Col, useScreenClass } from "react-grid-system"
import Slider from "react-slick"
import { useInView, InView } from "react-intersection-observer"
import ReactPlayer from "react-player"
import { rgba } from "polished"

import SEO from "../components/seo"
import Layout from "../components/layout"
import Main from "../components/main"
import CtaBanner from "../components/ctaBanner"
import CaseStudiesGrid from "../components/work/caseStudiesGrid"
import Video from "../components/video"
import VideosGrid from "../components/expertise/videosGrid"

const StyledMain = styled(Main)`
  margin-top: 0;
  padding-bottom: 0;
  padding-top: 0;
`

const SectionHero = styled.section`
  padding-bottom: 3.55rem;
`

const Section = styled.section`
  padding-bottom: 5rem;
`

const Hero = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  .slick-slider {
    flex-grow: 1;
  }
`

const PageTitle = styled.h1`
  color: ${(props) => props.theme.colors.white};
  position: absolute;
  z-index: 1;
`

const H2 = styled.h2``

const H3 = styled.h3`
  margin-bottom: 0.71em;
`

const VideothumbWrapper = styled.div`
  cursor: pointer;
`

const StyledVideosGrid = styled(VideosGrid)`
  margin-top: 16px;
`

const Modal = styled.div`
  align-items: center;
  background-color: ${(props) => rgba(props.theme.colors.black, 0.75)};
  bottom: 0;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  opacity: 1;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 99999;
`

const ModalContent = styled.div`
  flex: 1 0 100%;
  margin: auto;
  max-width: 80%;
  position: relative;
  width: 100%;
  .react-player-wrapper {
    height: 0;
    padding-top: 56.25%;
    width: 100%;
  }
  .react-player {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  &::before,
  &::after {
    background-color: ${(props) => props.theme.colors.white};
    content: "";
    display: block;
    height: 1px;
    position: absolute;
    right: -8px;
    top: -40px;
    width: 66px;
  }
  &::after {
    transform: rotate(135deg);
  }
  &::before {
    transform: rotate(45deg);
  }
`

const Content = styled.div`
  margin-bottom: 3.1rem;
`

const WrapperWwd = styled.div`
  margin-bottom: 5rem;
`

const TitleWwd = styled.p`
  font-weight: 500;
  line-height: 1.5rem;
  margin-bottom: 1rem;
`

const UlWwd = styled.ul`
  list-style: none;
  line-height: 0.75rem;
  margin: 0;
`

const SectionCaseStudies = styled.section`
  padding-bottom: 0;
  padding-top: 0;
`

const CaseStudiesH2 = styled.h2`
  margin-bottom: 0;
  padding-bottom: 4.6rem;
`

const WhatWeDid = ({ services }) => {
  return (
    Array.isArray(services) && (
      <WrapperWwd>
        <TitleWwd>What we did:</TitleWwd>
        <UlWwd>
          {services.map((service, index) => (
            <li key={index}>{service.name}</li>
          ))}
        </UlWwd>
      </WrapperWwd>
    )
  )
}

const WorkPage = (props) => {
  const {
    clientName,
    slug,
    cta,
    heroImages,
    images1,
    images2,
    images3,
    images4,
    images5,
    images6,
    images7,
    headline2,
    headline3,
    headline4,
    headline5,
    headline6,
    headline7,
    description2,
    description3,
    description4,
    description5,
    description6,
    description7,
    results,
    resultsStat1,
    resultsStat1Text,
    resultsStat2,
    resultsStat2Text,
    resultsStat3,
    resultsStat3Text,
    situation,
    solution,
    tagline,
    videos1,
    videos2,
    videos3,
    whatWeDid,
    caseStudies,
    seoTitle,
    seoHelmet,
    seoDescription,
    openGraphImage,
  } = props.data.contentfulWorkPage

  const screenClass = useScreenClass()
  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  const [ref, inView, entry] = useInView({
    rootMargin: "0px 0px -99% 0px",
  })

  const [showModal, setShowModal] = useState(false)
  const [modalVideoId, setModalVideoId] = useState(null)

  const toggleModal = (video) => {
    console.log(video)
    if (!showModal) {
      setShowModal(true)
      setModalVideoId(video.videoId)
    } else {
      setShowModal(false)
      setModalVideoId(null)
    }
  }

  useEffect(() => {
    if (inView) {
      document.body.classList.add("is-intersecting__true")
    } else {
      document.body.classList.remove("is-intersecting__true")
    }
  }, [inView])

  return <>
    <SEO
      title={seoTitle ? seoTitle : ``}
      description={
        seoDescription && seoDescription.seoDescription
          ? seoDescription.seoDescription
          : ``
      }
      pageUrl={props?.location?.href}
      image={openGraphImage?.localFile?.publicURL}
    >
      {seoHelmet?.seoHelmet || null}
    </SEO>
    <Layout>
      <StyledMain>
        <SectionHero ref={ref} id={`work__hero__${slug}`}>
          <Container style={{ padding: 0, maxWidth: "100%" }}>
            <Row nogutter>
              <Col>
                <Hero>
                  <PageTitle>{clientName}</PageTitle>
                  {heroImages && Array.isArray(heroImages) && (
                    <Slider {...settings}>
                      {heroImages.map(({ localFile, fluid }, index) => (
                        <GatsbyImage
                          image={localFile && localFile.childImageSharp
                            ? localFile.childImageSharp.gatsbyImageData
                            : fluid}
                          key={index}
                          sizes={
                            localFile && localFile.childImageSharp
                              ? {
                                  ...localFile.childImageSharp.gatsbyImageData,
                                  aspectRatio: 1920 / 733,
                                }
                              : { ...fluid, aspectRatio: 1920 / 733 }
                          }
                          style={{ minWidth: `100%`, minHeight: `600px` }} />
                      ))}
                    </Slider>
                  )}
                </Hero>
              </Col>
            </Row>
          </Container>
        </SectionHero>

        {tagline ||
        situation ||
        whatWeDid ||
        Array.isArray(images1) ||
        Array.isArray(videos1) ? (
          <Section>
            <Container
              style={{
                paddingLeft: ["md", "lg", "xl"].includes(screenClass)
                  ? "3rem"
                  : "1.5625rem",
                paddingRight: ["md", "lg", "xl"].includes(screenClass)
                  ? "3rem"
                  : "1.5625rem",
              }}
            >
              <Row>
                <Col
                  lg={6}
                  offset={{ lg: 2 }}
                  style={{
                    paddingRight: ["lg", "xl"].includes(screenClass)
                      ? "3rem"
                      : ".5rem",
                  }}
                >
                  <p className={`headline tagline`}>
                    <span dangerouslySetInnerHTML={{ __html: tagline }} />
                  </p>
                  <H3 className={`highlight`}>The Situation</H3>
                  <Content>
                    {documentToReactComponents(JSON.parse(situation.raw))}
                  </Content>
                </Col>
                <Col
                  col={2}
                  style={{
                    marginLeft: ["lg", "xl"].includes(screenClass)
                      ? "3rem"
                      : "0",
                  }}
                >
                  <WhatWeDid services={whatWeDid} />
                </Col>
              </Row>
            </Container>
            {Array.isArray(images1) && (
              <Container style={{ padding: 0, maxWidth: "100%" }}>
                <Row nogutter>
                  {images1.map((image, index) => (
                    <Col key={index} md={12}>
                      {image.file.contentType.slice(
                        0,
                        image.file.contentType.indexOf("/")
                      ) === "image" && (
                        <GatsbyImage
                          image={image.localFile && image.localFile.childImageSharp
                            ? image.localFile.childImageSharp.gatsbyImageData
                            : fluid} />
                      )}
                      {image.file.contentType.slice(
                        0,
                        image.file.contentType.indexOf("/")
                      ) === "video" && <Video {...image} key={index} />}
                    </Col>
                  ))}
                </Row>
              </Container>
            )}
            {Array.isArray(videos1) && (
              <Container style={{ padding: 0, maxWidth: "100%" }}>
                <Row nogutter>
                  {videos1.map((video, index) => (
                    <Col key={index} md={12}>
                      <VideothumbWrapper onClick={(e) => toggleModal(video)}>
                        <GatsbyImage
                          image={video.thumbnail.localFile &&
                          video.thumbnail.localFile.childImageSharp
                            ? video.thumbnail.localFile.childImageSharp.gatsbyImageData
                            : video.thumbnail.fluid} />
                      </VideothumbWrapper>
                      {modalVideoId === video.videoId && showModal && (
                        <Modal
                          key={`${video.slug}-modal`}
                          className={`${video.slug}-modal`}
                          onClick={(e) => toggleModal(video)}
                        >
                          <ModalContent>
                            <div className={`react-player-wrapper`}>
                              <ReactPlayer
                                className={`react-player`}
                                url={
                                  video.channel === "Vimeo"
                                    ? `https://player.vimeo.com/video/${video.videoId}`
                                    : ``
                                }
                                controls
                                width="100%"
                                height="100%"
                              />
                            </div>
                          </ModalContent>
                        </Modal>
                      )}
                    </Col>
                  ))}
                </Row>
              </Container>
            )}
          </Section>
        ) : null}

        {headline2 || description2 || Array.isArray(images2) ? (
          <Section>
            {headline2 || description2 ? (
              <Container
                style={{
                  paddingLeft: ["md", "lg", "xl"].includes(screenClass)
                    ? "3rem"
                    : "1.5625rem",
                  paddingRight: ["md", "lg", "xl"].includes(screenClass)
                    ? "3rem"
                    : "1.5625rem",
                }}
              >
                <Row>
                  <Col lg={8} offset={{ lg: 2 }}>
                    {headline2 && (
                      <H3>
                        <span
                          dangerouslySetInnerHTML={{ __html: headline2 }}
                        />
                      </H3>
                    )}
                    {description2 && (
                      <Content>
                        <span
                          dangerouslySetInnerHTML={{ __html: description2 }}
                        />
                      </Content>
                    )}
                  </Col>
                </Row>
              </Container>
            ) : null}
            {Array.isArray(images2) && (
              <Container style={{ padding: 0, maxWidth: "100%" }}>
                <Row nogutter>
                  {images2.map((image, index) => (
                    <Col key={index} md={12}>
                      {image.file.contentType.slice(
                        0,
                        image.file.contentType.indexOf("/")
                      ) === "image" && (
                        <GatsbyImage
                          image={image.localFile && image.localFile.childImageSharp
                            ? image.localFile.childImageSharp.gatsbyImageData
                            : fluid} />
                      )}
                      {image.file.contentType.slice(
                        0,
                        image.file.contentType.indexOf("/")
                      ) === "video" && <Video {...image} key={index} />}
                    </Col>
                  ))}
                </Row>
              </Container>
            )}
          </Section>
        ) : null}

        {headline3 || description3 || Array.isArray(images3) ? (
          <Section>
            {headline3 || description3 ? (
              <Container
                style={{
                  paddingLeft: ["md", "lg", "xl"].includes(screenClass)
                    ? "3rem"
                    : "1.5625rem",
                  paddingRight: ["md", "lg", "xl"].includes(screenClass)
                    ? "3rem"
                    : "1.5625rem",
                }}
              >
                <Row>
                  <Col lg={8} offset={{ lg: 2 }}>
                    {headline3 && (
                      <H3>
                        <span
                          dangerouslySetInnerHTML={{ __html: headline3 }}
                        />
                      </H3>
                    )}
                    {description3 && (
                      <Content>
                        <span
                          dangerouslySetInnerHTML={{ __html: description3 }}
                        />
                      </Content>
                    )}
                  </Col>
                </Row>
              </Container>
            ) : null}
            {Array.isArray(images3) && (
              <Container style={{ padding: 0, maxWidth: "100%" }}>
                <Row nogutter>
                  {images3.map((image, index) => (
                    <Col key={index} md={12}>
                      {image.file.contentType.slice(
                        0,
                        image.file.contentType.indexOf("/")
                      ) === "image" && (
                        <GatsbyImage
                          image={image.localFile && image.localFile.childImageSharp
                            ? image.localFile.childImageSharp.gatsbyImageData
                            : fluid} />
                      )}
                      {image.file.contentType.slice(
                        0,
                        image.file.contentType.indexOf("/")
                      ) === "video" && <Video {...image} key={index} />}
                    </Col>
                  ))}
                </Row>
              </Container>
            )}
          </Section>
        ) : null}

        {headline4 || description4 || Array.isArray(images4) ? (
          <Section>
            {headline4 || description4 ? (
              <Container
                style={{
                  paddingLeft: ["md", "lg", "xl"].includes(screenClass)
                    ? "3rem"
                    : "1.5625rem",
                  paddingRight: ["md", "lg", "xl"].includes(screenClass)
                    ? "3rem"
                    : "1.5625rem",
                }}
              >
                <Row>
                  <Col lg={8} offset={{ lg: 2 }}>
                    {headline4 && (
                      <H3>
                        <span
                          dangerouslySetInnerHTML={{ __html: headline4 }}
                        />
                      </H3>
                    )}
                    {description4 && (
                      <Content>
                        <span
                          dangerouslySetInnerHTML={{ __html: description4 }}
                        />
                      </Content>
                    )}
                  </Col>
                </Row>
              </Container>
            ) : null}
            {Array.isArray(images4) && (
              <Container style={{ padding: 0, maxWidth: "100%" }}>
                <Row nogutter>
                  {images4.map((image, index) => (
                    <Col key={index} md={12}>
                      {image.file.contentType.slice(
                        0,
                        image.file.contentType.indexOf("/")
                      ) === "image" && (
                        <GatsbyImage
                          image={image.localFile && image.localFile.childImageSharp
                            ? image.localFile.childImageSharp.gatsbyImageData
                            : fluid} />
                      )}
                      {image.file.contentType.slice(
                        0,
                        image.file.contentType.indexOf("/")
                      ) === "video" && <Video {...image} key={index} />}
                    </Col>
                  ))}
                </Row>
              </Container>
            )}
          </Section>
        ) : null}

        {headline5 ||
        description5 ||
        Array.isArray(images5) ||
        Array.isArray(videos2) ||
        Array.isArray(videos3) ? (
          <Section>
            {headline5 || description5 ? (
              <Container
                style={{
                  paddingLeft: ["md", "lg", "xl"].includes(screenClass)
                    ? "3rem"
                    : "1.5625rem",
                  paddingRight: ["md", "lg", "xl"].includes(screenClass)
                    ? "3rem"
                    : "1.5625rem",
                }}
              >
                <Row>
                  <Col lg={8} offset={{ lg: 2 }}>
                    {headline5 && (
                      <H3>
                        <span
                          dangerouslySetInnerHTML={{ __html: headline5 }}
                        />
                      </H3>
                    )}
                    {description5 && (
                      <Content>
                        <span
                          dangerouslySetInnerHTML={{ __html: description5 }}
                        />
                      </Content>
                    )}
                  </Col>
                </Row>
              </Container>
            ) : null}
            {Array.isArray(images5) && (
              <Container style={{ padding: 0, maxWidth: "100%" }}>
                <Row nogutter>
                  {images5.map((image, index) => (
                    <Col key={index} md={12}>
                      {image.file.contentType.slice(
                        0,
                        image.file.contentType.indexOf("/")
                      ) === "image" && (
                        <GatsbyImage
                          image={image.localFile && image.localFile.childImageSharp
                            ? image.localFile.childImageSharp.gatsbyImageData
                            : fluid} />
                      )}
                      {image.file.contentType.slice(
                        0,
                        image.file.contentType.indexOf("/")
                      ) === "video" && <Video {...image} key={index} />}
                    </Col>
                  ))}
                </Row>
              </Container>
            )}
            {Array.isArray(videos2) && (
              <StyledVideosGrid
                videos={videos2}
                className={`overlay--hover`}
              />
            )}
            {Array.isArray(videos3) && (
              <Container style={{ padding: 0, maxWidth: "100%" }}>
                <Row nogutter>
                  {videos3.map((video, index) => (
                    <Col key={index} md={12}>
                      <VideothumbWrapper onClick={(e) => toggleModal(video)}>
                        <GatsbyImage
                          image={video.thumbnail.localFile &&
                          video.thumbnail.localFile.childImageSharp
                            ? video.thumbnail.localFile.childImageSharp.gatsbyImageData
                            : video.thumbnail.fluid} />
                      </VideothumbWrapper>
                      {modalVideoId === video.videoId && showModal && (
                        <Modal
                          key={`${video.slug}-modal`}
                          className={`${video.slug}-modal`}
                          onClick={(e) => toggleModal(video)}
                        >
                          <ModalContent>
                            <div className={`react-player-wrapper`}>
                              <ReactPlayer
                                className={`react-player`}
                                url={
                                  video.channel === "Vimeo"
                                    ? `https://player.vimeo.com/video/${video.videoId}`
                                    : ``
                                }
                                controls
                                width="100%"
                                height="100%"
                              />
                            </div>
                          </ModalContent>
                        </Modal>
                      )}
                    </Col>
                  ))}
                </Row>
              </Container>
            )}
          </Section>
        ) : null}

        {headline6 || description6 || Array.isArray(images6) ? (
          <Section>
            {headline6 || description6 ? (
              <Container
                style={{
                  paddingLeft: ["md", "lg", "xl"].includes(screenClass)
                    ? "3rem"
                    : "1.5625rem",
                  paddingRight: ["md", "lg", "xl"].includes(screenClass)
                    ? "3rem"
                    : "1.5625rem",
                }}
              >
                <Row>
                  <Col lg={8} offset={{ lg: 2 }}>
                    {headline6 && (
                      <H3>
                        <span
                          dangerouslySetInnerHTML={{ __html: headline6 }}
                        />
                      </H3>
                    )}
                    {description6 && (
                      <Content>
                        <span
                          dangerouslySetInnerHTML={{ __html: description6 }}
                        />
                      </Content>
                    )}
                  </Col>
                </Row>
              </Container>
            ) : null}
            {Array.isArray(images6) && (
              <Container style={{ padding: 0, maxWidth: "100%" }}>
                <Row nogutter>
                  {images6.map((image, index) => (
                    <Col key={index} md={12}>
                      {image.file.contentType.slice(
                        0,
                        image.file.contentType.indexOf("/")
                      ) === "image" && (
                        <GatsbyImage
                          image={image.localFile && image.localFile.childImageSharp
                            ? image.localFile.childImageSharp.gatsbyImageData
                            : fluid} />
                      )}
                      {image.file.contentType.slice(
                        0,
                        image.file.contentType.indexOf("/")
                      ) === "video" && <Video {...image} key={index} />}
                    </Col>
                  ))}
                </Row>
              </Container>
            )}
          </Section>
        ) : null}

        {headline7 || description7 || Array.isArray(images7) ? (
          <Section>
            {headline7 || description7 ? (
              <Container
                style={{
                  paddingLeft: ["md", "lg", "xl"].includes(screenClass)
                    ? "3rem"
                    : "1.5625rem",
                  paddingRight: ["md", "lg", "xl"].includes(screenClass)
                    ? "3rem"
                    : "1.5625rem",
                }}
              >
                <Row>
                  <Col lg={8} offset={{ lg: 2 }}>
                    {headline7 && (
                      <H3>
                        <span
                          dangerouslySetInnerHTML={{ __html: headline7 }}
                        />
                      </H3>
                    )}
                    {description7 && (
                      <Content>
                        <span
                          dangerouslySetInnerHTML={{ __html: description7 }}
                        />
                      </Content>
                    )}
                  </Col>
                </Row>
              </Container>
            ) : null}
            {Array.isArray(images7) && (
              <Container style={{ padding: 0, maxWidth: "100%" }}>
                <Row nogutter>
                  {images7.map((image, index) => (
                    <Col key={index} md={12}>
                      {image.file.contentType.slice(
                        0,
                        image.file.contentType.indexOf("/")
                      ) === "image" && (
                        <GatsbyImage
                          image={image.localFile && image.localFile.childImageSharp
                            ? image.localFile.childImageSharp.gatsbyImageData
                            : fluid} />
                      )}
                      {image.file.contentType.slice(
                        0,
                        image.file.contentType.indexOf("/")
                      ) === "video" && <Video {...image} key={index} />}
                    </Col>
                  ))}
                </Row>
              </Container>
            )}
          </Section>
        ) : null}

        {(results && results.raw) ||
        resultsStat1 ||
        resultsStat2 ||
        resultsStat3 ? (
          <Section>
            <Container
              style={{
                paddingLeft: ["md", "lg", "xl"].includes(screenClass)
                  ? "3rem"
                  : "1.5625rem",
                paddingRight: ["md", "lg", "xl"].includes(screenClass)
                  ? "3rem"
                  : "1.5625rem",
              }}
            >
              <Row>
                <Col lg={8} offset={{ lg: 2 }}>
                  <H3 className={`headline highlight`}>The Results</H3>
                  {results && results.raw && (
                    <Content>
                      {documentToReactComponents(JSON.parse(results.raw))}
                    </Content>
                  )}
                </Col>
              </Row>
              <Row>
                <Col lg={8} offset={{ lg: 2 }}>
                  <Row justify="between">
                    {resultsStat1 && (
                      <Col>
                        <p className={`h1 highlight text-center`}>
                          {resultsStat1}
                        </p>
                        {resultsStat1Text && (
                          <p className={`text-center`}>{resultsStat1Text}</p>
                        )}
                      </Col>
                    )}
                    {resultsStat2 && (
                      <Col>
                        <p className={`h1 highlight text-center`}>
                          {resultsStat2}
                        </p>
                        {resultsStat2Text && (
                          <p className={`text-center`}>{resultsStat2Text}</p>
                        )}
                      </Col>
                    )}
                    {resultsStat3 && (
                      <Col>
                        <p className={`h1 highlight text-center`}>
                          {resultsStat3}
                        </p>
                        {resultsStat3Text && (
                          <p className={`text-center`}>{resultsStat3Text}</p>
                        )}
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            </Container>
          </Section>
        ) : null}

        {caseStudies && (
          <SectionCaseStudies>
            <Container
              style={{
                paddingLeft: ["md", "lg", "xl"].includes(screenClass)
                  ? "3rem"
                  : "1.5625rem",
                paddingRight: ["md", "lg", "xl"].includes(screenClass)
                  ? "3rem"
                  : "1.5625rem",
              }}
            >
              <Row>
                <Col lg={8} offset={{ lg: 2 }}>
                  <CaseStudiesH2 className={`headline sub`}>
                    Related Works
                  </CaseStudiesH2>
                </Col>
              </Row>
            </Container>
            <CaseStudiesGrid caseStudies={caseStudies} />
          </SectionCaseStudies>
        )}
      </StyledMain>
      <CtaBanner {...cta} />
    </Layout>
  </>;
}

export const query = graphql`
  query WorkSingleQuery($slug: String!) {
    contentfulWorkPage(slug: { eq: $slug }) {
      id
      clientName
      slug
      heroImages {
        localFile {
          ...workHero
        }
        fluid(maxWidth: 1920, quality: 95) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      tagline
      situation {
        raw
      }
      whatWeDid {
        name
      }
      solution {
        raw
      }
      results {
        raw
      }
      headline2
      description2
      headline3
      description3
      headline4
      description4
      headline5
      description5
      headline6
      description6
      headline7
      description7
      resultsStat1
      resultsStat1Text
      resultsStat2
      resultsStat2Text
      resultsStat3
      resultsStat3Text
      images1 {
        file {
          contentType
        }
        localFile {
          ...workImage
          publicURL
        }
        fluid(maxWidth: 1920, quality: 95) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      images2 {
        file {
          contentType
        }
        localFile {
          ...workImage
          publicURL
        }
        fluid(maxWidth: 1920, quality: 95) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      images3 {
        file {
          contentType
        }
        localFile {
          ...workImage
          publicURL
        }
        fluid(maxWidth: 1920, quality: 95) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      images4 {
        file {
          contentType
        }
        localFile {
          ...workImage
          publicURL
        }
        fluid(maxWidth: 1920, quality: 95) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      images5 {
        file {
          contentType
        }
        localFile {
          ...workImage
          publicURL
        }
        fluid(maxWidth: 1920, quality: 95) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      images6 {
        file {
          contentType
        }
        localFile {
          ...workImage
          publicURL
        }
        fluid(maxWidth: 1920, quality: 95) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      images7 {
        file {
          contentType
        }
        localFile {
          ...workImage
          publicURL
        }
        fluid(maxWidth: 1920, quality: 95) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      videos1 {
        thumbnail {
          localFile {
            ...workImage
            publicURL
          }
          fluid(maxWidth: 1920, quality: 95) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        videoId
        channel
      }
      videos2 {
        thumbnail {
          localFile {
            ...workImage
            publicURL
          }
          fluid(maxWidth: 1920, quality: 95) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        videoId
        channel
        previewText
      }
      videos3 {
        thumbnail {
          localFile {
            ...workImage
            publicURL
          }
          fluid(maxWidth: 1920, quality: 95) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        videoId
        channel
        previewText
      }
      caseStudies {
        id
        clientName
        previewText
        previewMedia {
          file {
            contentType
          }
          localFile {
            ...workPreview
            publicURL
          }
          fluid(maxWidth: 920, quality: 95) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        previewWeight
        category {
          name
          slug
        }
        slug
      }
      cta {
        id
        name
        ctaTitle
        ctaBody
        ctaButton
      }
      seoTitle
      seoDescription {
        seoDescription
      }
      seoHelmet {
        seoHelmet
      }
      openGraphImage {
        localFile {
          publicURL
        }
      }
    }
  }
`

export default WorkPage
